(function () {

  const html5Types = {
    email: 'email',
    url: 'url',
    date: 'date',
    time: 'time',
    week: 'week',
    month: 'month',
    number: 'number',
    color: 'color',
    range: 'number',
  };

  const html5Attributes = ['min', 'max', 'accept', 'pattern', 'minlength', 'maxlength'];

  const getFirstFormElementByName = function (node) {
    if (!node.form) return null;
    return Array.from(node.form.elements).find(function (elm) {
      return elm.name == node.name;
    });
  };

  const getFormElementsByName = function (node) {
    if (!node.form) return [];
    return Array.from(node.form.elements).filter(function (elm) {
      return elm.name == node.name;
    });
  };

  dmx.bootstrapVersion = 0;
  dmx.bootstrap4forms = false;
  dmx.bootstrap5forms = false;
  dmx.rules = {};

  dmx.ready(() => {
    dmx.bootstrapVersion = window.bootstrap && bootstrap.Alert ? parseInt(bootstrap.Alert.VERSION) : 0;
    dmx.bootstrap4forms = dmx.bootstrapVersion == 4;
    dmx.bootstrap5forms = dmx.bootstrapVersion == 5;
  });

  dmx.validate = function (node) {
    if (!node) {
      console.warn('dmx.validate() called without a node!');
      return true;
    }

    if (node.tagName == 'FORM') {
      let valid = true;

      for (var i = 0; i < node.elements.length; i++) {
        if (!dmx.validate(node.elements[i])) {
          valid = false;
        }
      }

      if (Array.isArray(node.dmxExtraElements)) {
        for (var i = 0; i < node.dmxExtraElements.length; i++) {
          if (!dmx.validate(node.dmxExtraElements[i])) {
            valid = false;
          }
        }
      }

      node.classList.add('was-validated');

      return valid;
    }

    node.dirty = true;

    if (node.type == 'checkbox' || node.type == 'radio') {
      // is this behavior correct?
      const firstCheckbox = getFirstFormElementByName(node);
      if (firstCheckbox && node != firstCheckbox) {
        return dmx.validate(firstCheckbox);
      }
    }

    if (!node.willValidate) {
      node.setCustomValidity('');
      return true;
    }

    // Google reCAPTCHA is always required
    if (node.getAttribute('name') == 'g-recaptcha-response' && !validity(node, 'required')) {
      return false;
    }

    // validate required
    if (node.hasAttribute('required') && !validity(node, 'required')) {
      return false;
    }

    if (node.value && node.value.length) {
      // validate html5 types and attributes
      for (let type in html5Types) {
        if (node.type == type && !validity(node, html5Types[type])) {
          return false;
        }
      }

      for (let attr of html5Attributes) {
        if (node.hasAttribute(attr) && !validity(node, attr, node.getAttribute(attr))) {
          return false;
        }
      }

      for (let attr of node.attributes) {
        if (attr.name.startsWith('data-rule-')) {
          const rule = attr.name.slice(10).toLowerCase();

          if (!validity(node, rule, attr.value)) {
            return false;
          }
        }
      }
    }

    node.setCustomValidity('');

    return true;
  };

  dmx.validateReset = function (node) {
    if (node.tagName == 'FORM') {
      for (let elm of node.elements) {
        dmx.validateReset(elm);
      }

      node.classList.remove('was-validated');

      return;
    }

    node.dirty = false;
    node.setCustomValidity('');
    node.classList.remove('dmxValidator-valid', 'dmxValidator-invalid', 'is-valid', 'is-invalid');

    /*
    if (dmx.bootstrap5forms) {
      if ((node.type == 'checkbox' || node.type == 'radio') && node.name) {
        dmx.validate.setBootstrap5Message(node, '');
        var nodes = dmx.array(node.form.querySelectorAll('input[name="' + node.name + '"]'));
        nodes.forEach(function (node) {
          node.classList.remove('is-valid', 'is-invalid');
        });
      } else {
        node.classList.remove('is-valid', 'is-invalid');
      }
    } else if (dmx.bootstrap4forms) {
      dmx.validate.setBootstrap4Message(node, '');
      if ((node.type == 'checkbox' || node.type == 'radio') && node.name) {
        dmx.validate.setBootstrap4Message(node, '');
        var nodes = dmx.array(node.form.querySelectorAll('input[name="' + node.name + '"]'));
        nodes.forEach(function (node) {
          node.classList.remove('is-valid', 'is-invalid');
        });
      } else {
        node.classList.remove('is-valid', 'is-invalid');
      }
    } else {
      dmx.validate.setErrorMessage(node, '');
      node.classList.remove('dmxValidator-valid', 'dmxValidator-invalid');
    }
    */
  };

  function validity(node, rule, param) {
    var valid = true;

    if (dmx.rules[rule]) {
      valid = dmx.rules[rule].validity(node, param);
      if (!valid) setMessage(node, rule, param);
    } else {
      console.warn('Validation rule ' + rule + ' not found!');
    }

    if (valid) {
      dmx.validate.setMessage(node, '');
    }

    return valid;
  }

  function setMessage(node, rule, param) {
    var message = node.getAttribute('data-msg-' + rule) || dmx.rules[rule].message;
    if (Array.isArray(param)) {
      message = message.replace(/\{(\d)\}/g, function (match, i) {
        return param[i];
      });
    } else {
      message = message.replace(/\{0\}/g, param);
    }

    dmx.validate.setMessage(node, message);
  }

  dmx.validate.setMessage = function (node, message) {
    node.setCustomValidity(message);

    if (dmx.bootstrapVersion) {
      dmx.validate.setBootstrapMessage(node, message);
    } else {
      dmx.validate.setErrorMessage(node, message);
    }
  }

  dmx.validate.setErrorMessage = function (node, message) {
    if (node.hasAttribute('data-msg-custom') || !(node.getAttribute('name') || node.getAttribute('id'))) {
      return;
    }

    var type = node.type.toLowerCase();
    var id =
      'dmxValidatorError' +
      (node.form ? node.form.getAttribute('id') : '') +
      (node.getAttribute('name') || node.getAttribute('id'));
    var err = document.getElementById(id);

    if (!err) {
      err = document.createElement('span');
      err.id = id;
      err.className = 'dmxValidator-error';

      if (type == 'checkbox' || type == 'radio') {
        var group = node.closest('.checkbox-group, .radio-group');

        if (group) {
          group.insertAdjacentElement('afterend', err);
        } else {
          node.insertAdjacentElement('afterend', err);
        }
      } else {
        node.insertAdjacentElement('afterend', err);
      }
    }

    err.textContent = message;

    if (message) {
      node.classList.remove('dmxValidator-valid');
      node.classList.add('dmxValidator-invalid');
    } else {
      node.classList.remove('dmxValidator-invalid');
      node.classList.add('dmxValidator-valid');
    }
  };

  dmx.validate.setBootstrapMessage = function (node, message) {
    if (node.hasAttribute('data-msg-custom') || !(node.getAttribute('name') || node.getAttribute('id'))) {
      return;
    }

    var type = node.type.toLowerCase();
    var name = node.getAttribute('name');
    var id = 'dmxValidatorError' + (node.form ? node.form.getAttribute('id') : '') + (name || node.getAttribute('id'));
    var err = document.getElementById(id);

    if (!err) {
      err = document.createElement('div');
      err.id = id;
      err.className = 'invalid-feedback';
      if ((type == 'checkbox' || type == 'radio') && name) {
        var nodes = node.form.querySelectorAll('input[name="' + name + '"]');
        if (nodes.length) {
          node = nodes[nodes.length - 1];
        }
      }
      node.parentElement.appendChild(err);
    }

    if (node.hasAttribute('medium-editor-textarea-id')) {
      // special case for medium-editor
      node = document.getElementById(node.getAttribute('medium-editor-textarea-id'));
    }

    if (node.getAttribute('is') == 'dmx-tagify') {
      // special case for dmx-tagify
      if (node.previousElementSibling && node.previousElementSibling.classList.contains('tagify')) {
        node = node.previousElementSibling;
      }
    }

    if (type != 'hidden' && node.name != 'g-recaptcha-response') {
      if ((type == 'checkbox' || type == 'radio') && name) {
        var nodes = getFormElementsByName(node);
        nodes.forEach(function (node) {
          node.setCustomValidity(message);

          if (message) {
            node.classList.remove('is-valid');
            node.classList.add('is-invalid');
          } else {
            node.classList.remove('is-invalid');
            node.classList.add('is-valid');
          }
        });
      } else {
        if (message) {
          node.classList.remove('is-valid');
          node.classList.add('is-invalid');
        } else {
          node.classList.remove('is-invalid');
          node.classList.add('is-valid');
        }
      }
    }

    err.textContent = message;
  };
})();
